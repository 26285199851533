<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="padding: 10px;"
                cols="12"
                lg="4"
                md="4"
                sm="12"
            >
                <h3>Pipeline - Sales</h3>
            </v-col>
            <v-col 
                style="padding-top: 10px; display: flex;"
                cols="12"
                lg="4"
                md="4"
                sm="12"
            >
                <v-combobox
                    v-model="sortSelected"
                    :items="listSortOptions"
                    label="Sort By"
                    item-text="description"
                    item-value="id"
                    clearable
                    outlined
                    @change="sortRegisters"
                    dense
                ></v-combobox>

                <v-tooltip bottom v-if="sortType == 0">
                    <template v-slot:activator="{ on }" v-if="sortType == 0">
                        <v-btn class="btnToogle" v-on="on" v-if="sortType == 0" @click="sortType = 1; sortRegisters()">
                            <v-icon>mdi-sort-ascending</v-icon>
                        </v-btn>
                    </template>
                    <span>Order by ascending</span>
                </v-tooltip>

                <v-tooltip bottom v-if="sortType == 1">
                    <template v-slot:activator="{ on }" v-if="sortType == 1">
                        <v-btn class="btnToogle" v-on="on" v-if="sortType == 1" @click="sortType = 0; sortRegisters()">
                            <v-icon>mdi-sort-descending</v-icon>
                        </v-btn>
                    </template>
                    <span>Order by descending</span>
                </v-tooltip>
            </v-col>
            <v-col 
                style="padding: 10px;"
                cols="12"
                lg="4"
                md="4"
                sm="12"
            >
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        label="Filter Current View"
                        prepend-inner-icon="mdi mdi-filter"
                        outlined
                        single-line
                        @keyup="filteredSearch"
                        @click:prepend-inner="getRegisters"
                        dense
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>

        <div id="divHeaders" style="width: 100%; overflow-x: auto" class="sticky" @scroll="syncScroll">

            <v-row 
                class="rowWrap"
            >
                <v-col 
                    class="colWrap"
                    style="background-color: white !important;"
                    cols="12"
                    lg="3"
                    md="6"
                    sm="12"
                >
                    <v-card>
                        <v-col 
                            cols="12"
                            class="d-flex flex-row-reverse"
                        >
                            <label style="font-size: 17px !important;"> {{ totalInProgress }} </label>
                            <v-icon left>mdi mdi-cash-multiple</v-icon>
                        </v-col>
                        <v-card-title class="text-h6 lighten-2 pipelineTitle inProgress">
                            <v-col 
                                cols="6"
                            >
                                <v-icon left class="pipelineWhiteColor">mdi mdi-arrow-top-right</v-icon>
                                <label class="pipelineWhiteColor">In Progress</label>
                            </v-col>
                            <v-col 
                                cols="6"
                                style="text-align: right;"
                            >
                                <label class="pipelineWhiteColor"> {{ qtdInProgress }} </label>
                            </v-col>
                        </v-card-title>
                    </v-card>
                </v-col>

                <v-col 
                    class="colWrap"
                    style="background-color: white !important;"
                    cols="12"
                    lg="3"
                    md="6"
                    sm="12"
                >
                    <v-card>
                        <v-col 
                            cols="12"
                            class="d-flex flex-row-reverse"
                        >
                            <label style="font-size: 17px !important;"> {{ totalWaitingApproval }} </label>
                            <v-icon left>mdi mdi-cash-multiple</v-icon>
                        </v-col>
                        <v-card-title class="text-h6 lighten-2 pipelineTitle waitingApproval">
                            <v-col 
                                cols="10"
                            >
                                <v-icon left class="pipelineWhiteColor">mdi mdi-timer-sand</v-icon>
                                <label class="pipelineWhiteColor">Waiting Approval</label>
                            </v-col>
                            <v-col 
                                cols="2"
                                style="text-align: right;"
                            >
                                <label class="pipelineWhiteColor"> {{ qtdWaitingApproval }} </label>
                            </v-col>
                        </v-card-title>
                    </v-card>
                </v-col>

                <v-col 
                    class="colWrap"
                    style="background-color: white !important;"
                    cols="12"
                    lg="3"
                    md="6"
                    sm="12"
                >
                    <v-card>
                        <v-col 
                            cols="12"
                            class="d-flex flex-row-reverse"
                        >
                            <label style="font-size: 17px !important;"> {{ totalFinishProposal }} </label>
                            <v-icon left>mdi mdi-cash-multiple</v-icon>
                        </v-col>
                        <v-card-title class="text-h6 lighten-2  pipelineTitle finishProposal">
                            <v-col 
                                cols="10"
                            >
                                <v-icon left class="pipelineWhiteColor">mdi mdi-note-edit-outline</v-icon>
                                <label class="pipelineWhiteColor">Finish Proposal</label>
                            </v-col>
                            <v-col 
                                cols="2"
                                style="text-align: right;"
                            >
                                <label class="pipelineWhiteColor"> {{ qtdFinishProposal }} </label>
                            </v-col>
                        </v-card-title>
                    </v-card>
                </v-col>

                <v-col 
                    class="colWrap"
                    style="background-color: white !important;"
                    cols="12"
                    lg="3"
                    md="6"
                    sm="12"
                >
                    <v-card>
                        <v-col 
                            cols="12"
                            class="d-flex flex-row-reverse"
                        >
                            <label style="font-size: 17px !important;"> {{ totalContracted }} </label>
                            <v-icon left>mdi mdi-cash-multiple</v-icon>
                        </v-col>
                        <v-card-title class="text-h6 lighten-2 pipelineTitle contracted">
                            <v-col 
                                cols="10"
                            >
                                <v-icon left class="pipelineWhiteColor">mdi mdi-check-decagram-outline</v-icon>
                                <label class="pipelineWhiteColor">Contracted</label>
                            </v-col>
                            <v-col 
                                cols="2"
                                style="text-align: right;"
                            >
                                <label class="pipelineWhiteColor"> {{ qtdContracted }} </label>
                            </v-col>
                        </v-card-title>
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <div id="divContents" style="width: 100%; overflow-x: auto" @scroll="syncScroll">
            <v-row 
                class="rowWrap"
            >
                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="3"
                    md="6"
                    sm="12"
                >   
                    <v-card 
                        class="pipelineDetails"
                        v-for="(item, index) in listInProgressFiltered" :key="index"
                    >
                        <v-progress-linear 
                            :value="(100/4) * parseInt(item.step)" 
                            style="margin-top: 20px"
                        >
                        </v-progress-linear>
                        <v-card-text 
                        >
                            <label class="labelMainColor" @click="goToServiceDetails(item.id)">{{ item.idFormatted }}</label>

                            <div
                                class="moreOptions"
                            >
                                <v-menu
                                    left
                                    bottom
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="buttonMoreOptions"
                                            color=""
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            :to="{ name: 'serviceDetails', params: {id: item.id} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-arrow-expand</v-icon> Job Specifics</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :to="{ name: 'scopeOfWork', params: {id: item.id} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-note-edit-outline</v-icon> Scope Of Work</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :to="{ name: 'loadCalculation', params: {id: item.id} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-calculator-variant-outline</v-icon> Load Calculation</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="item.status <= 7"
                                            @click="showDialogUnclosedDeal(item.id)"
                                        >
                                            <v-list-item-title><v-icon left>mdi-pen-off</v-icon> Deal not closed</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                            <br />
                            <br />

                            <label>{{ item.customerName }}</label>
                            <br />
                            <label>{{ item.customerAddress }} </label>
                            <br />
                            <label>{{ item.customerCity }} </label>
                            <br v-if="haveGateCode(item)" />
                            <label v-if="haveGateCode(item)">Gate Code: {{ item.customerAddressGateCode }}</label>
                            <br />
                            <span>{{ item.customerPhone }}</span>
                            <br />
                            <span>{{ item.customerEmail }}</span>
                            <h5 style="color: #bbb">
                                Deadline: <strong>{{ item.dateScopeWorkStep1Formatted }}
                                <span :style="item.dateScopeWorkStep1DeadlineDays == 2 ? 'color: var(--color__pipeline_deadline_warning)' : item.dateScopeWorkStep1DeadlineDays < 2 ? 'color: var(--color__pipeline_deadline_over_due)' : 'color: var(--color__pipeline_deadline_ok)'">( {{ item.dateScopeWorkStep1DeadlineDaysDescription }} )</span> </strong></h5>
                        </v-card-text>

                        <v-divider v-if="userLoggedGetters.profile.viewAllSalesPersonPipeline == 1"></v-divider>

                        <v-card-actions
                            v-if="userLoggedGetters.profile.viewAllSalesPersonPipeline == 1"
                        >
                            <v-row style="margin: 1px !important;">
                                <v-col 
                                    class="margin-top"
                                    cols="6"
                                    lg="8"
                                    md="6"
                                    sm="6"
                                >
                                    <v-icon left>mdi mdi-account-tie</v-icon>
                                    <label >{{ item.sellerName }}</label>
                                </v-col>
                                <v-col 
                                    class="margin-top"
                                    style="text-align: right;"
                                    cols="6"
                                    lg="4"
                                    md="6"
                                    sm="6"
                                >
                                    <label>{{ item.estimatedBudgetFormatted }}</label>
                                </v-col>
                            </v-row>
                        </v-card-actions>

                        <v-card-actions class="justify-center">
                            <v-col 
                                cols="2"
                                v-if="item.dateScopeWorkStep1DeadlineDays < 2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            v-on="on" 
                                            left
                                            large
                                        >
                                            mdi mdi-alert-octagram
                                        </v-icon>
                                    </template>
                                    <span>Late</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-if="item.showIconBBQ == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-grill-outline
                                        </v-icon>
                                    </template>
                                    <span>Barbecue</span>
                                </v-tooltip>
                            </v-col>
                            <v-col 
                                v-if="item.showIconPergola == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_pergola)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-home-roof
                                        </v-icon>
                                    </template>
                                    <span>Pergolas</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                v-if="item.showIconNotes == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-notebook-edit-outline
                                        </v-icon>
                                    </template>
                                    <span>Notes</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-for="(itemProduct, index) in item.listProductsWithIcons" :key="index"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            {{ itemProduct.iconProduct }}
                                        </v-icon>
                                    </template>
                                    <span> {{ itemProduct.productDescription }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="showImportantMessage(item)"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            left 
                                            v-on="on" 
                                            large
                                        >
                                            mdi mdi-note-alert-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ item.importantMessage }}</span>
                                </v-tooltip>
                            </v-col>
                        </v-card-actions>
                    </v-card>
                    
                </v-col>

                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="3"
                    md="6"
                    sm="12"
                >
                    <v-card 
                        class="pipelineDetails"
                        v-for="(item, index) in listWaitingApprovalFiltered" :key="index"
                    >
                        <v-progress-linear 
                            :value="(100/4) * parseInt(item.step)" 
                            style="margin-top: 20px"
                        >
                        </v-progress-linear>
                        <v-card-text 
                        >
                            <label class="labelMainColor" @click="goToServiceDetails(item.id)">{{ item.idFormatted }}</label>

                            <div
                                class="moreOptions"
                            >
                                <v-menu
                                    left
                                    bottom
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="buttonMoreOptions"
                                            color=""
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            :to="{ name: 'serviceDetails', params: {id: item.id} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-arrow-expand</v-icon> Job Specifics</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="userLoggedGetters.profile.approvesProposal == 1"
                                            :to="{ name: 'reviewProposal', params: {id: item.id} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-checkbox-marked-outline</v-icon> Review Proposal</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :to="{ name: 'loadCalculation', params: {id: item.id} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-calculator-variant-outline</v-icon> Load Calculation</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="item.status <= 7"
                                            @click="showDialogUnclosedDeal(item.id)"
                                        >
                                            <v-list-item-title><v-icon left>mdi-pen-off</v-icon> Deal not closed</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                            <br />
                            <br />

                            <label>{{ item.customerName }}</label>
                            <br />
                            <label>{{ item.customerAddress }} </label>
                            <br />
                            <label>{{ item.customerCity }} </label>
                            <br v-if="haveGateCode(item)" />
                            <label v-if="haveGateCode(item)">Gate Code: {{ item.customerAddressGateCode }}</label>
                            <br />
                            <span>{{ item.customerPhone }}</span>
                            <br />
                            <span>{{ item.customerEmail }}</span>
                            <h5 style="color: #bbb">
                                Deadline: <strong>{{ item.dateScopeWorkStep2Formatted }}
                                <span :style="item.dateScopeWorkStep2DeadlineDays == 2 ? 'color: var(--color__pipeline_deadline_warning)' : item.dateScopeWorkStep2DeadlineDays < 2 ? 'color: var(--color__pipeline_deadline_over_due)' : 'color: var(--color__pipeline_deadline_ok)'">( {{ item.dateScopeWorkStep2DeadlineDaysDescription }} )</span> </strong></h5>
                            
                        </v-card-text>

                        <v-divider v-if="userLoggedGetters.profile.viewAllSalesPersonPipeline == 1"></v-divider>

                        <v-card-actions
                            v-if="userLoggedGetters.profile.viewAllSalesPersonPipeline == 1"
                        >
                            <v-row style="margin: 1px !important;">
                                <v-col 
                                    class="margin-top"
                                    cols="6"
                                    lg="8"
                                    md="6"
                                    sm="6"
                                >
                                    <v-icon left>mdi mdi-account-tie</v-icon>
                                    <label >{{ item.sellerName }}</label>
                                </v-col>
                                <v-col 
                                    class="margin-top"
                                    style="text-align: right;"
                                    cols="6"
                                    lg="4"
                                    md="6"
                                    sm="6"
                                >
                                    <label >{{ item.contractValueFormatted }}</label>
                                </v-col>
                            </v-row>
                        </v-card-actions>

                        <v-card-actions class="justify-center">
                            <v-col 
                                cols="2"
                                v-if="item.dateScopeWorkStep2DeadlineDays < 2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            v-on="on" 
                                            left
                                            large
                                        >
                                            mdi mdi-alert-octagram
                                        </v-icon>
                                    </template>
                                    <span>Late</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-if="item.showIconBBQ == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-grill-outline
                                        </v-icon>
                                    </template>
                                    <span>Barbecue</span>
                                </v-tooltip>
                            </v-col>
                            <v-col 
                                v-if="item.showIconPergola == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_pergola)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-home-roof
                                        </v-icon>
                                    </template>
                                    <span>Pergolas</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                v-if="item.showIconNotes == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-notebook-edit-outline
                                        </v-icon>
                                    </template>
                                    <span>Notes</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-for="(itemProduct, index) in item.listProductsWithIcons" :key="index"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            {{ itemProduct.iconProduct }}
                                        </v-icon>
                                    </template>
                                    <span> {{ itemProduct.productDescription }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="showImportantMessage(item)"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            left 
                                            v-on="on" 
                                            large
                                        >
                                            mdi mdi-note-alert-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ item.importantMessage }}</span>
                                </v-tooltip>
                            </v-col>
                        </v-card-actions>
                    </v-card>
                    
                </v-col>

                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="3"
                    md="6"
                    sm="12"
                >
                    <v-card 
                        class="pipelineDetails"
                        v-for="(item, index) in listFinishProposalFiltered" :key="index"
                    >
                        <v-progress-linear 
                            :value="(100/4) * parseInt(item.step)" 
                            style="margin-top: 20px"
                        >
                        </v-progress-linear>
                        <v-card-text 
                        >
                            <label class="labelMainColor" @click="goToServiceDetails(item.id)">{{ item.idFormatted }}</label>

                            <div
                                class="moreOptions"
                            >
                                <v-menu
                                    left
                                    bottom
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="buttonMoreOptions"
                                            color=""
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            :to="{ name: 'serviceDetails', params: {id: item.id} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-arrow-expand</v-icon> Job Specifics</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :to="{ name: 'scopeOfWork', params: {id: item.id, setStep: 3} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-note-edit-outline</v-icon> Scope Of Work</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :to="{ name: 'loadCalculation', params: {id: item.id} }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-calculator-variant-outline</v-icon> Load Calculation</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="item.status <= 7"
                                            @click="showDialogUnclosedDeal(item.id)"
                                        >
                                            <v-list-item-title><v-icon left>mdi-pen-off</v-icon> Deal not closed</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                            <br />
                            <br />

                            <label>{{ item.customerName }}</label>
                            <br />
                            <label>{{ item.customerAddress }} </label>
                            <br />
                            <label>{{ item.customerCity }} </label>
                            <br v-if="haveGateCode(item)" />
                            <label v-if="haveGateCode(item)">Gate Code: {{ item.customerAddressGateCode }}</label>
                            <br />
                            <span>{{ item.customerPhone }}</span>
                            <br />
                            <span>{{ item.customerEmail }}</span>
                            <h5 style="color: #bbb">
                                Deadline: <strong>{{ item.dateScopeWorkStep3Formatted }}
                                <span :style="item.dateScopeWorkStep3DeadlineDays == 2 ? 'color: var(--color__pipeline_deadline_warning)' : item.dateScopeWorkStep3DeadlineDays < 2 ? 'color: var(--color__pipeline_deadline_over_due)' : 'color: var(--color__pipeline_deadline_ok)'">( {{ item.dateScopeWorkStep3DeadlineDaysDescription }} )</span> </strong></h5>
                        </v-card-text>

                        <v-divider v-if="userLoggedGetters.profile.viewAllSalesPersonPipeline == 1"></v-divider>

                        <v-card-actions
                            v-if="userLoggedGetters.profile.viewAllSalesPersonPipeline == 1"
                        >
                            <v-row style="margin: 1px !important;">
                                <v-col 
                                    class="margin-top"
                                    cols="6"
                                    lg="8"
                                    md="6"
                                    sm="6"
                                >
                                    <v-icon left>mdi mdi-account-tie</v-icon>
                                    <label >{{ item.sellerName }}</label>
                                </v-col>
                                <v-col 
                                    class="margin-top"
                                    style="text-align: right;"
                                    cols="6"
                                    lg="4"
                                    md="6"
                                    sm="6"
                                >
                                    <label >{{ item.contractValueFormatted }}</label>
                                </v-col>
                            </v-row>
                        </v-card-actions>

                        <v-card-actions class="justify-center">
                            <v-col 
                                cols="2"
                                v-if="item.dateScopeWorkStep3DeadlineDays < 2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            v-on="on" 
                                            left
                                            large
                                        >
                                            mdi mdi-alert-octagram
                                        </v-icon>
                                    </template>
                                    <span>Late</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-if="item.showIconBBQ == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-grill-outline
                                        </v-icon>
                                    </template>
                                    <span>Barbecue</span>
                                </v-tooltip>
                            </v-col>
                            <v-col 
                                v-if="item.showIconPergola == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_pergola)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-home-roof
                                        </v-icon>
                                    </template>
                                    <span>Pergolas</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                v-if="item.showIconNotes == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-notebook-edit-outline
                                        </v-icon>
                                    </template>
                                    <span>Notes</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-for="(itemProduct, index) in item.listProductsWithIcons" :key="index"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            {{ itemProduct.iconProduct }}
                                        </v-icon>
                                    </template>
                                    <span> {{ itemProduct.productDescription }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="showImportantMessage(item)"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            left 
                                            v-on="on" 
                                            large
                                        >
                                            mdi mdi-note-alert-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ item.importantMessage }}</span>
                                </v-tooltip>
                            </v-col>
                        </v-card-actions>
                    </v-card>
                    
                </v-col>

                <v-col 
                    class="colWrap"
                    cols="12"
                    lg="3"
                    md="6"
                    sm="12"
                >
                    <v-card 
                        class="pipelineDetails"
                        v-for="(item, index) in listContractedFiltered" :key="index"
                    >
                        <v-progress-linear 
                            :value="(100/4) * parseInt(item.step)" 
                            style="margin-top: 20px"
                        >
                        </v-progress-linear>
                        <v-card-text 
                        >
                            <label class="labelMainColor" @click="goToServiceDetails(item.id)">{{ item.idFormatted }}</label>

                            <div
                                class="moreOptions"
                            >
                                <v-menu
                                    left
                                    bottom
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="buttonMoreOptions"
                                            color=""
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            :to="{ name: 'serviceDetails', params: { id: item.id } }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-arrow-expand</v-icon> Job Specifics</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :to="{ name: 'scopeOfWork', params: { id: item.id, setStep: 4 } }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-note-edit-outline</v-icon> Scope Of Work</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :to="{ name: 'loadCalculation', params: { id: item.id } }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-calculator-variant-outline</v-icon> Load Calculation</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :to="{ name: 'addendum', params: { id: item.id } }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-file-plus-outline</v-icon> Addendum</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :to="{ name: 'colorSelection', params: { id: item.id } }"
                                        >
                                            <v-list-item-title><v-icon left>mdi-palette</v-icon> Color Selection</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                             v-if="item.status <= 7"
                                            @click="showDialogUnclosedDeal(item.id)"
                                        >
                                            <v-list-item-title><v-icon left>mdi-pen-off</v-icon> Deal not closed</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                            <br />
                            <br />

                            <label>{{ item.customerName }}</label>
                            <br />
                            <label>{{ item.customerAddress }} </label>
                            <br />
                            <label>{{ item.customerCity }} </label>
                            <br v-if="haveGateCode(item)" />
                            <label v-if="haveGateCode(item)">Gate Code: {{ item.customerAddressGateCode }}</label>
                            <br />
                            <span>{{ item.customerPhone }}</span>
                            <br />
                            <span>{{ item.customerEmail }}</span>
                            <h5 style="color: #bbb" v-if="item.status == 7">
                                Deadline: <strong>{{ item.dateScopeWorkStep4Formatted }}
                                <span :style="item.dateScopeWorkStep4DeadlineDays == 2 ? 'color: var(--color__pipeline_deadline_warning)' : item.dateScopeWorkStep4DeadlineDays < 2 ? 'color: var(--color__pipeline_deadline_over_due)' : 'color: var(--color__pipeline_deadline_ok)'">( {{ item.dateScopeWorkStep4DeadlineDaysDescription }} )</span> </strong></h5>
                        </v-card-text>

                        <v-divider v-if="userLoggedGetters.profile.viewAllSalesPersonPipeline == 1"></v-divider>

                        <v-card-actions
                            v-if="userLoggedGetters.profile.viewAllSalesPersonPipeline == 1"
                        >
                            <v-row style="margin: 1px !important;">
                                <v-col 
                                    class="margin-top"
                                    cols="6"
                                    lg="8"
                                    md="6"
                                    sm="6"
                                >
                                    <v-icon left>mdi mdi-account-tie</v-icon>
                                    <label >{{ item.sellerName }}</label>
                                </v-col>
                                <v-col 
                                    class="margin-top"
                                    style="text-align: right;"
                                    cols="6"
                                    lg="4"
                                    md="6"
                                    sm="6"
                                >
                                    <label >{{ item.contractValueFormatted }}</label>
                                </v-col>
                            </v-row>
                        </v-card-actions>

                        <v-card-actions class="justify-center">
                            <v-col 
                                cols="2"
                                v-if="item.status <= PROJECT_STATUS.SALES_CONTRACTED && item.dateScopeWorkStep4DeadlineDays < 2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            v-on="on" 
                                            left
                                            large
                                        >
                                            mdi mdi-alert-octagram
                                        </v-icon>
                                    </template>
                                    <span>Late</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-if="item.showIconBBQ == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-grill-outline
                                        </v-icon>
                                    </template>
                                    <span>Barbecue</span>
                                </v-tooltip>
                            </v-col>
                            <v-col 
                                v-if="item.showIconPergola == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_pergola)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-home-roof
                                        </v-icon>
                                    </template>
                                    <span>Pergolas</span>
                                </v-tooltip>
                            </v-col>

                            <v-col
                                v-if="item.showIconNotes == 1"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            mdi mdi-notebook-edit-outline
                                        </v-icon>
                                    </template>
                                    <span>Notes</span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                v-for="(itemProduct, index) in item.listProductsWithIcons" :key="index"
                                cols="2"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__construction_dashboard_bbq)"
                                            left 
                                            v-on="on"
                                            large    
                                        >
                                            {{ itemProduct.iconProduct }}
                                        </v-icon>
                                    </template>
                                    <span> {{ itemProduct.productDescription }} </span>
                                </v-tooltip>
                            </v-col>

                            <v-col 
                                cols="2"
                                v-if="showImportantMessage(item)"
                            >
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon 
                                            color="var(--color__alert)"
                                            left 
                                            v-on="on" 
                                            large
                                        >
                                            mdi mdi-note-alert-outline
                                        </v-icon>
                                    </template>
                                    <span>{{ item.importantMessage }}</span>
                                </v-tooltip>
                            </v-col>
                        </v-card-actions>
                    </v-card>
                </v-col>

            </v-row>
        </div>

        <v-speed-dial
            fixed
            v-model="openedFloatButton"
            :top="false"
            :bottom="true"
            :right="true"
            :left="false"
            :direction="'top'"
            :transition="'slide-y-reverse-transition'"
        >
            <template v-slot:activator>
                <v-btn
                    class="mx-2"
                    elevation="24"
                    outlined
                    x-large
                    fab
                    style="background-color: var(--color__red) !important; color: white !important;"
                    @click="goToService()"
                >
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
        </v-speed-dial>

        <DialogUnclosedDeal
            :idAux="dialogUnclosedDeal.idAux"
            :id="dialogUnclosedDeal.id"
            :idService="dialogUnclosedDeal.idService"
            v-on:methodConfirmToCall="dialogUnclosedDeal.methodConfirm"
            :showDialogUnclosedDeal.sync="dialogUnclosedDeal.show"
        />

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import { filterValuePart, orderBy } from '@/utilities/Utils';
    import DialogUnclosedDeal from "@/components/Services/DialogUnclosedDeal";
    import { PROJECT_STATUS } from "@/utilities/Enums";

    export default ({

        components: {
            DialogUnclosedDeal
        },

        mixins: [Helpers],

        data: () => ({

            filter: {
                fastSearch: ''
            },

            openedFloatButton: false,

            serviceId: 0,

            totalEstimatedBudget: "$ 0,00",

            listInProgress: [],
            listInProgressFiltered: [],
            qtdInProgress: 0,
            totalInProgress: "$ 0,00",

            listWaitingApproval: [],
            listWaitingApprovalFiltered: [],
            qtdWaitingApproval: 0,
            totalWaitingApproval: "$ 0,00",

            listFinishProposal: [],
            listFinishProposalFiltered: [],
            qtdFinishProposal: 0,
            totalFinishProposal: "$ 0,00",

            listContracted: [],
            listContractedFiltered: [],
            qtdContracted: 0,
            totalContracted: "$ 0,00",

            showModal: true,

            dialogUnclosedDeal: {
                idAux: Math.random(),
                id: 0,
                idService: 0,
                show: false,
                methodConfirm: ( () => {} ),
            },

            sortSelected: null,
            sortType: 0,
            listSortOptions: [
                { id: 1, description: 'Registration Date', listField: ['id'] },
                { id: 2, description: 'Deadline', listField: ['dateScopeWorkStep1DeadlineDays', 'dateScopeWorkStep2DeadlineDays', 'dateScopeWorkStep3DeadlineDays', 'dateScopeWorkStep4DeadlineDays'] },
                { id: 3, description: 'Budget', listField: ['estimatedBudget'] }
            ],

            PROJECT_STATUS

        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),
        },

        methods: {

            showImportantMessage(item) {
                return item.importantMessage != null && 
                       item.importantMessage != undefined && 
                       item.importantMessage.toString().trim() != ""
            },

            goToServiceDetails(id) {
                this.$router.push({ name: "serviceDetails", params: {id: id} });
            },

            goToService() {
                this.$router.push({ path: "/service/serviceForm" });
            },
			
            getSituationColor (situation) {

                let color = "";

                switch (situation) {

                    case 1:
                        color = 'var(--color__main)';
                        break;

                    case 2:
                        color = 'var(--color__red)';
                        break;

                    case 3:
                        color = 'var(--color__completed)';
                        break;
                }

                return color
            },
            
            haveGateCode(item) {
                return item.customerAddressGateCode != null && item.customerAddressGateCode != undefined && item.customerAddressGateCode != ''
            },

            filteredSearch() {

                let filter = this.filter.fastSearch.toLowerCase();
                
                this.listInProgressFiltered = filterValuePart(this.listInProgress, filter, ['step', 'customerAddress', 'customerCity', 'dateScopeWorkStep4Formatted', 'sellerName', 'customerName', 'idFormatted']);
                this.listWaitingApprovalFiltered = filterValuePart(this.listWaitingApproval, filter, ['step', 'customerAddress', 'customerCity', 'dateScopeWorkStep4Formatted', 'sellerName', 'customerName', 'idFormatted']);
                this.listFinishProposalFiltered = filterValuePart(this.listFinishProposal, filter, ['step', 'customerAddress', 'customerCity', 'dateScopeWorkStep4Formatted', 'sellerName', 'customerName', 'idFormatted']);
                this.listContractedFiltered = filterValuePart(this.listContracted, filter, ['step', 'customerAddress', 'customerCity', 'dateScopeWorkStep4Formatted', 'sellerName', 'customerName', 'idFormatted']);

                return null;
            },

            sortRegisters() {

                let field = ['id'];

                if (this.sortSelected != null && this.sortSelected != undefined) {
                    field = this.sortSelected.listField;
                }

                if (field.length == 1) {
                    this.listInProgressFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[0]) });
                    this.listWaitingApprovalFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[0]) });
                    this.listFinishProposalFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[0]) });
                    this.listContractedFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[0]) });
                }
                else {
                    this.listInProgressFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[0]) });
                    this.listWaitingApprovalFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[1]) });
                    this.listFinishProposalFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[2]) });
                    this.listContractedFiltered.sort((a, b) => { return orderBy(a, b, this.sortType, field[3]) });
                }
            },

            async checkNotes(idService) {
                this.listNote = await this.$store.dispatch("serviceModule/ListProjectNote", { 
                    idService, 
                    idServiceProject: 0, 
                    onlyCheckExists: true, 
                    idStatus: 0, 
                    idPanel: 0, 
                    idStage: 0,
                    idUser: 0
                } );
                if (this.listNote.length > 0) return 1; else return 0;
            },

            async checkProductsIcons(idService) {
                return await this.$store.dispatch("productModule/ListProductsWithIconInProject", { idService, idServiceProject: 0 });
            },

            async checkIcons() {
                this.listInProgressFiltered.forEach(async item => {
                    item.showIconNotes = await this.checkNotes(item.id);
                    item.listProductsWithIcons = await this.checkProductsIcons(item.id);

                    this.$forceUpdate();
                })

                this.listWaitingApprovalFiltered.forEach(async item => {
                    item.showIconNotes = await this.checkNotes(item.id);
                    item.listProductsWithIcons = await this.checkProductsIcons(item.id);

                    this.$forceUpdate();
                })
                
                this.listFinishProposalFiltered.forEach(async item => {
                    item.showIconNotes = await this.checkNotes(item.id);
                    item.listProductsWithIcons = await this.checkProductsIcons(item.id);

                    this.$forceUpdate();
                })
                
                this.listContractedFiltered.forEach(async item => {
                    item.showIconNotes = await this.checkNotes(item.id);
                    item.listProductsWithIcons = await this.checkProductsIcons(item.id);

                    this.$forceUpdate();
                })
            },

            async getRegisters() {
                let response = await this.$store.dispatch("serviceModule/ListPipelineSalesPerson");
                this.sortSelected = this.listSortOptions[0];

                if (response != null && response != undefined) {

                    if (response.totalEstimatedBudgetFormatted != null && response.totalEstimatedBudgetFormatted != undefined) {
                        this.totalEstimatedBudget = response.totalEstimatedBudgetFormatted;
                    }
                        
                    if (response.listPipeline && response.listPipeline != null && response.listPipeline != undefined) {

                        this.listInProgress = [...response.listPipeline.filter(pip => pip.status == 3)];
                        if (this.listInProgress != null && this.listInProgress != undefined) {
                            this.qtdInProgress = this.listInProgress.length;
                            this.totalInProgress = response.totalInProgressFormatted;
                        }
                        this.listInProgressFiltered = [...this.listInProgress];

                        this.listWaitingApproval = [...response.listPipeline.filter(pip => pip.status == 5)];
                        if (this.listWaitingApproval != null && this.listWaitingApproval != undefined) {
                            this.qtdWaitingApproval = this.listWaitingApproval.length;
                            this.totalWaitingApproval = response.totalWaitingApprovalFormatted;
                        }
                        this.listWaitingApprovalFiltered = [...this.listWaitingApproval];

                        this.listFinishProposal = [...response.listPipeline.filter(pip => pip.status == 6)];
                        if (this.listFinishProposal != null && this.listFinishProposal != undefined) {
                            this.qtdFinishProposal = this.listFinishProposal.length;
                            this.totalFinishProposal = response.totalFinishProposalFormatted;
                        }
                        this.listFinishProposalFiltered = [...this.listFinishProposal];

                        this.listContracted = [...response.listPipeline.filter(pip => pip.status >= 7)];
                        if (this.listContracted != null && this.listContracted != undefined) {
                            this.qtdContracted = this.listContracted.length;
                            this.totalContracted = response.totalContractedFormatted;
                        }
                        this.listContractedFiltered = [...this.listContracted];

                        await this.checkIcons();
                    }
                }
            },

            async showDialogUnclosedDeal(id) {
                this.dialogUnclosedDeal = {
                    idAux: Math.random(),
                    id: 0,
                    idService: id,
                    show: true,
                    methodConfirm: ( () => {  
                        this.getRegisters();
                    })
                }
            },
        },

        async created() {
            this.showLoading();
            await this.getRegisters();
            this.hideLoading();
        },
        
    })
</script>

<style scoped>

    .v-card__actions {
        padding: 0px;
    }

    .margin-top {
        margin-top: 0px;
    }

    .inProgress {
        background-color: var(--color__pipeline_in_progress);
    }

    .waitingApproval {
        background-color: var(--color__pipeline_waiting_approval)
    }

    .finishProposal {
        background-color: var(--color__pipeline_finish_proposal)
    }

    .contracted {
        background-color: var(--color__main);
    }
</style>